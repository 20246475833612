"use strict";
$(document).on('turbolinks:load', function () {
	if ($('.as5002-generator').is(':visible')) {
		function copyToClipboard(elem) {
			// create hidden text element, if it doesn't already exist
			var targetId = "_hiddenCopyText_";
			var isInput = elem.tagName === "INPUT" || elem.tagName === "TEXTAREA";
			var origSelectionStart, origSelectionEnd;
			if (isInput) {
				// can just use the original source element for the selection and copy
				target = elem;
				origSelectionStart = elem.selectionStart;
				origSelectionEnd = elem.selectionEnd;
			} else {
				// must use a temporary form element for the selection and copy
				target = document.getElementById(targetId);
				if (!target) {
					var target = document.createElement("textarea");
					target.style.position = "absolute";
					target.style.left = "-9999px";
					target.style.top = "0";
					target.id = targetId;
					document.body.appendChild(target);
				}
				target.textContent = elem.textContent;
			}
			// select the content
			var currentFocus = document.activeElement;
			target.focus();
			target.setSelectionRange(0, target.value.length);

			// copy the selection
			var succeed;
			try {
				succeed = document.execCommand("copy");
			} catch (e) {
				succeed = false;
			}
			// restore original focus
			if (currentFocus && typeof currentFocus.focus === "function") {
				currentFocus.focus();
			}

			if (isInput) {
				// restore prior selection
				elem.setSelectionRange(origSelectionStart, origSelectionEnd);
			} else {
				// clear temporary content
				target.textContent = "";
			}
			return succeed;
		}
		// function resetAllRadio() {
		// 	$('.radio-as5002').prop('checked', false);
		// 	$('.radio-as5002').removeClass('radio-disable');
		// 	$('#dFrequency1').val('');
		// 	$('#dFrequency2').val('');
		// 	$('#dFrequency3').val('');
		// }
		function changePartNumber() {
			var frmDataRadio15 = $('#frmGroup15').serializeArray();
			var frmDataRadio18 = $('#frmGroup18').serializeArray();
			var frmDataRadio23 = $('#frmGroup23').serializeArray();
			var frmDataRadio26 = $('#frmGroup26').serializeArray();
			var frmDataRadio55 = $('#frmGroup55').serializeArray();
			var frmDataRadio40 = $('#frmGroup40').serializeArray();
			var frmDataRadio10 = $('#frmGroup10').serializeArray();

			$('#partNumber').val('');
			$('#btnRequest').prop('disabled', true)

			let arrFrequencies = [];
			for(let num of [1,2,3]){
				var dFrequency = $('#dFrequency' + num).val();
				dFrequency = parseFloat(dFrequency);
				if (dFrequency && dFrequency !== 0) {
					if (dFrequency < 0.01) {
						dFrequency = 0.01;
					}
					if (dFrequency > 350) {
						dFrequency = 350;
					}
					$('#dFrequency' + num).val(dFrequency);
					arrFrequencies.push(dFrequency)
				}
	
				var radioNum = 10;
				if (num > 2) {
					radioNum = 55;
				} else if (num > 1) {
					radioNum = 40;
				}
	
				if (dFrequency > 212.5) {
					$('#radio' + radioNum + '_H').prop('checked', false);
					$('#radio' + radioNum + '_I').prop('checked', false);
					$('#radio' + radioNum + '_J').prop('checked', false);
	
					$('#radio' + radioNum + '_H').prop('disabled', true);
					$('#radio' + radioNum + '_I').prop('disabled', true);
					$('#radio' + radioNum + '_J').prop('disabled', true);
	
					$('#div_radio' + radioNum + '_H').addClass('radio-disable');
					$('#div_radio' + radioNum + '_I').addClass('radio-disable');
					$('#div_radio' + radioNum + '_J').addClass('radio-disable');
				} else {
					$('#radio' + radioNum + '_H').prop('disabled', false);
					$('#radio' + radioNum + '_I').prop('disabled', false);
					$('#radio' + radioNum + '_J').prop('disabled', false);
	
					$('#div_radio' + radioNum + '_H').removeClass('radio-disable');
					$('#div_radio' + radioNum + '_I').removeClass('radio-disable');
					$('#div_radio' + radioNum + '_J').removeClass('radio-disable');
				}
			}

			

			if (!arrFrequencies.includes(NaN)  && frmDataRadio15.length > 0 && frmDataRadio18.length > 0 && frmDataRadio23.length > 0 && frmDataRadio26.length > 0 && frmDataRadio55.length > 0 && frmDataRadio40.length > 0 && frmDataRadio10.length > 0) {
				// var partNumber = '5002' + frmDataOutput[0].value + 'A' + frmDataRadio26[0].value + strFrequency + frmDataRadio18[0].value + 'B' + frmDataRadio15[0].value + frmDataRadio23[0].value;
				var partNumber = JSON.stringify({
					chip: '5002',
					totalStability: 'A',
					controlPin: frmDataRadio26[0].value,

					frequencyLow: $('#dFrequency3').val(),
					outputFormatLow: frmDataRadio55[0].value,
					frequencyHigh: $('#dFrequency2').val(),
					outputFormatHigh: frmDataRadio40[0].value,
					frequencyHiz: $('#dFrequency1').val(),
					outputFormatHiz: frmDataRadio10[0].value,
					package: frmDataRadio18[0].value,

					deviceRevision: 'B',
					temperatureGrade: frmDataRadio15[0].value,
					packageQty: frmDataRadio23[0].value
				});
				// $('#partNumber' + num).val(partNumber);
				$('#partNumber').val(partNumber);
				$('#btnRequest').prop('disabled', false)
			} else {
				// $('#partNumber' + num).val('');
				$('#partNumber').val('');
				$('#btnRequest').prop('disabled', true)
			}
		}
		function changeFrequencyStep(num) {
			var dFrequency = $('#dFrequency' + num).val();
			var strFreq = dFrequency.toString();
			if (strFreq.indexOf('.') === -1) {
				$('#dFrequency' + num).attr('step', '1');
			} else {
				var decimalLen = strFreq.length - strFreq.indexOf('.') - 1;
				if (decimalLen === 0) {
					$('#dFrequency' + num).attr('step', '1');
				} else {
					var strStep = '0.';
					while (decimalLen > 1) {
						strStep = strStep + '0';
						decimalLen--;
					}
					strStep = strStep + '1';
					$('#dFrequency' + num).attr('step', parseFloat(strStep));
				}
			}

			if (dFrequency < 1) {
				if (strFreq.length > 9) {
					strFreq = strFreq.substring(0, 9);
					$('#dFrequency' + num).val(strFreq);
				}
			} else {
				if (strFreq.length > 8) {
					strFreq = strFreq.substring(0, 8);
					$('#dFrequency' + num).val(strFreq);
				}
			}
		}

		$('.radio-group15').on('click', function () {
			if ($('#radio15_E').is(':checked')) {
				$('#radio10_A').prop('checked', false);
				$('#radio10_B').prop('checked', false);
				$('#radio10_D').prop('checked', false);

				$('#radio10_A').prop('disabled', true);
				$('#radio10_B').prop('disabled', true);
				$('#radio10_D').prop('disabled', true);

				$('#div_radio10_A').addClass('radio-disable');
				$('#div_radio10_B').addClass('radio-disable');
				$('#div_radio10_D').addClass('radio-disable');

				$('#radio40_A').prop('checked', false);
				$('#radio40_B').prop('checked', false);
				$('#radio40_D').prop('checked', false);

				$('#radio40_A').prop('disabled', true);
				$('#radio40_B').prop('disabled', true);
				$('#radio40_D').prop('disabled', true);

				$('#div_radio40_A').addClass('radio-disable');
				$('#div_radio40_B').addClass('radio-disable');
				$('#div_radio40_D').addClass('radio-disable');

				$('#radio55_A').prop('checked', false);
				$('#radio55_B').prop('checked', false);
				$('#radio55_D').prop('checked', false);

				$('#radio55_A').prop('disabled', true);
				$('#radio55_B').prop('disabled', true);
				$('#radio55_D').prop('disabled', true);

				$('#div_radio55_A').addClass('radio-disable');
				$('#div_radio55_B').addClass('radio-disable');
				$('#div_radio55_D').addClass('radio-disable');
			} else {
				$('#radio10_A').prop('disabled', false);
				$('#radio10_B').prop('disabled', false);
				$('#radio10_D').prop('disabled', false);

				$('#div_radio10_A').removeClass('radio-disable');
				$('#div_radio10_B').removeClass('radio-disable');
				$('#div_radio10_D').removeClass('radio-disable');

				$('#radio40_A').prop('disabled', false);
				$('#radio40_B').prop('disabled', false);
				$('#radio40_D').prop('disabled', false);

				$('#div_radio40_A').removeClass('radio-disable');
				$('#div_radio40_B').removeClass('radio-disable');
				$('#div_radio40_D').removeClass('radio-disable');

				$('#radio55_A').prop('disabled', false);
				$('#radio55_B').prop('disabled', false);
				$('#radio55_D').prop('disabled', false);

				$('#div_radio55_A').removeClass('radio-disable');
				$('#div_radio55_B').removeClass('radio-disable');
				$('#div_radio55_D').removeClass('radio-disable');
			}
		});
		$('.radio-as5002').on('click', function () {
			changePartNumber();
		});
		$('#dFrequency1').keyup(function () {
			//changeFrequencyStep(1);
		});
		$('#dFrequency2').keyup(function () {
			//changeFrequencyStep(2);
		});
		$('#dFrequency3').keyup(function () {
			//changeFrequencyStep(3);
		});
		$('#dFrequency1, #dFrequency2, #dFrequency3').keydown(function (event) {
			if (event.shiftKey == true) {
				event.preventDefault();
			}

			if ((event.keyCode >= 48 && event.keyCode <= 57) ||
				(event.keyCode >= 96 && event.keyCode <= 105) ||
				event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 37 ||
				event.keyCode == 39 || event.keyCode == 46 || event.keyCode == 190 || event.keyCode == 110) {

			} else {
				event.preventDefault();
			}

			if ($(this).val().indexOf('.') !== -1 && (event.keyCode == 190 || event.keyCode == 110))
				event.preventDefault();
			//if a decimal has been added, disable the "."-button
		});
		$('#dFrequency1').on('change', function () {
			changePartNumber();
			//changeFrequencyStep();
		});
		$('#dFrequency2').on('change', function () {
			changePartNumber();
			//changeFrequencyStep();
		});
		$('#dFrequency3').on('change', function () {
			changePartNumber();
			//changeFrequencyStep();
		});
		$('.radio-group23').on('click', function () {
			if ($(this).val() === "") {
				$('#btnRequest').prop('disabled', false);
			} else {
				$('#btnRequest').prop('disabled', true);
			}
		});

		$('#btnReset').on('click', function () {
			$('.radio-as5002').prop('checked', false);
			$('.radio-as5002').removeClass('radio-disable');
			$('.radio-as5002').prop('disabled',false)
			$('#dFrequency1').prop('disabled',false).val('');
			$('#dFrequency2').prop('disabled',false).val('');
			$('#dFrequency3').prop('disabled',false).val('');
			// $('#partNumber1').val('');
			// $('#partNumber2').val('');
			// $('#partNumber3').val('');
			$('#partNumber').val('');
			$('#btnRequest').prop('disabled', true)
		});
		$('#btnCopy1').on('click', function () {
			copyToClipboard($('#partNumber1')[0]);
		});
		$('#btnRequest').on('click', function (event) {
			if ($("#partNumber").val() == ''){
				event.preventDefault();
			}
			// if ($('#partNumber1').val() == '' && $('#partNumber2').val() == '' && $('#partNumber3').val() == '') {
			// 	alert("Something went wrong with PartNumber");
			// 	return;
			// }

			// var partNumber = $('#partNumber1').val() + '|' + $('#partNumber2').val() + '|' + $('#partNumber3').val();
			// var dFreq = $('#dFrequency1').val() + ',' + $('#dFrequency2').val() + ',' + $('#dFrequency3').val();
			// if (partNumber) {
			// 	// var newUrl = gUrlWpCart + '?add-to-cart=' + gProductId + '&quantity=1&part_number=' + partNumber + '&dfreq=' + dFreq;
			// 	// window.top.location.replace(newUrl);
			// } else {
			// 	event.preventDefault();
			// }
		});
		$('#btnRequestA').on('click', function () {
			if (gUrlRequestQuoteFrom) {
				window.top.location.replace(gUrlRequestQuoteFrom);
			}
		});
		$('#btnBuy').on('click', function () {
			var partNumber = $('#partNumber1').val() + '|' + $('#partNumber2').val() + '|' + $('#partNumber3').val();
			if (partNumber && gUrlBuyOnDigiKey) {
				var newUrl = gUrlBuyOnDigiKey + '#' + partNumber;
				window.top.location.replace(newUrl);
			}
		});
	}
});