"use strict";
require('../jquery_inputed_mask');
$(document).on('turbolinks:load', function () {
	if ($('.as5003-generator').is(':visible')) {
		function copyToClipboard(elem) {
			// create hidden text element, if it doesn't already exist
			var targetId = "_hiddenCopyText_";
			var isInput = elem.tagName === "INPUT" || elem.tagName === "TEXTAREA";
			var origSelectionStart, origSelectionEnd;
			if (isInput) {
				// can just use the original source element for the selection and copy
				target = elem;
				origSelectionStart = elem.selectionStart;
				origSelectionEnd = elem.selectionEnd;
			} else {
				// must use a temporary form element for the selection and copy
				target = document.getElementById(targetId);
				if (!target) {
					var target = document.createElement("textarea");
					target.style.position = "absolute";
					target.style.left = "-9999px";
					target.style.top = "0";
					target.id = targetId;
					document.body.appendChild(target);
				}
				target.textContent = elem.textContent;
			}
			// select the content
			var currentFocus = document.activeElement;
			target.focus();
			target.setSelectionRange(0, target.value.length);

			// copy the selection
			var succeed;
			try {
				succeed = document.execCommand("copy");
			} catch (e) {
				succeed = false;
			}
			// restore original focus
			if (currentFocus && typeof currentFocus.focus === "function") {
				currentFocus.focus();
			}

			if (isInput) {
				// restore prior selection
				elem.setSelectionRange(origSelectionStart, origSelectionEnd);
			} else {
				// clear temporary content
				target.textContent = "";
			}
			return succeed;
		}
		// function resetAllRadio() {
		// 	$('.radio-as5003').prop('checked', false);
		// 	$('.radio-as5003').removeClass('radio-disable');
		// 	$('#dFrequency').val('');
		// 	$('#devI2cAddress').val('0x60');
		// }
		function changePartNumber() {
			var frmDataRadio57 = $('#frmGroup57').serializeArray();
			var frmDataRadio60 = $('#frmGroup60').serializeArray();
			var frmDataRadio88 = $('#frmGroup88').serializeArray();
			var frmDataRadio77 = $('#frmGroup77').serializeArray();
			var frmDataRadio37 = $('#frmGroup37').serializeArray();

			$('#partNumber').val('');
			$('#btnRequest').prop('disabled', true)

			var dFrequency;
			try {
				dFrequency = $('#dFrequency').val();
				if (!dFrequency) {
					return;
				}
				dFrequency = parseFloat(dFrequency);
				if (isNaN(dFrequency)) {
					alert('Please enter correct frequency');
				}
			} catch (e) {
				return;
			}
			if (dFrequency && dFrequency !== 0) {
				if (dFrequency < 0.01) {
					dFrequency = 0.01;
				}
				if (dFrequency > 350) {
					dFrequency = 350;
				}
				$('#dFrequency').val(dFrequency);
			} else {
				dFrequency = 0;
			}
			if (dFrequency > 212.5) {
				$('#radio37_H').prop('checked', false);
				$('#radio37_I').prop('checked', false);
				$('#radio37_J').prop('checked', false);

				$('#radio37_H').prop('disabled', true);
				$('#radio37_I').prop('disabled', true);
				$('#radio37_J').prop('disabled', true);

				$('#div_radio37_H').addClass('radio-disable');
				$('#div_radio37_I').addClass('radio-disable');
				$('#div_radio37_J').addClass('radio-disable');
			} else {
				$('#radio37_H').prop('disabled', false);
				$('#radio37_I').prop('disabled', false);
				$('#radio37_J').prop('disabled', false);

				$('#div_radio37_H').removeClass('radio-disable');
				$('#div_radio37_I').removeClass('radio-disable');
				$('#div_radio37_J').removeClass('radio-disable');
			}

			var strFrequency = '';
			if (dFrequency >= 100) {
				strFrequency = parseInt(dFrequency) + 'M' + Math.round((123 + 1 * dFrequency - parseInt(dFrequency)) * 10000);
			} else if (dFrequency >= 10) {
				strFrequency = parseInt(dFrequency) + 'M' + Math.round((123 + 1 * dFrequency - parseInt(dFrequency)) * 100000);
			} else if (dFrequency >= 1) {
				strFrequency = parseInt(dFrequency) + 'M' + Math.round((123 + 1 * dFrequency - parseInt(dFrequency)) * 1000000);
			} else if (dFrequency > 0) {
				strFrequency = 'M' + Math.round((123 + 1 * dFrequency - parseInt(dFrequency)) * 10000000);
			}
			if (strFrequency) {
				strFrequency = strFrequency.replace('M123', 'M');
			}
			if (strFrequency && frmDataRadio77.length > 0 && frmDataRadio57.length > 0 && frmDataRadio60.length > 0 && frmDataRadio88.length > 0 && frmDataRadio37.length > 0) {
				//var partNumber = '5003JAA' + $('#select6').val() + frmDataRadio37[0].value + frmDataRadio60[0].value + frmDataRadio57[0].value + frmDataRadio88[0].value;
				// var partNumber = '5003' + frmDataRadio37[0].value + 'A' + frmDataRadio77[0].value + strFrequency + frmDataRadio60[0].value + 'B' + frmDataRadio57[0].value + frmDataRadio88[0].value;

				var partNumber = JSON.stringify({
					chip: '5003',
					signalFormat: frmDataRadio37[0].value,
					totalStability: 'A',
					startupOutputStatus: frmDataRadio77[0].value,
					deviceI2CAddress: $('#devI2cAddress').val(),
					frequency: $('#dFrequency').val(),
					package: frmDataRadio60[0].value,
					deviceRevision: 'B',
					temperatureGrade: frmDataRadio57[0].value,
					packageQty: frmDataRadio88[0].value
				});

				$('#partNumber').val(partNumber);
				$('#btnRequest').prop('disabled', false)
			} else {
				$('#partNumber').val('');
				$('#btnRequest').prop('disabled', true)
			}
		}
		function decodePartNumber() {
			// resetAllRadio();
			var strPartNumber = $('#partNumber').val();
			strPartNumber = $.trim(strPartNumber);
			if (strPartNumber.length >= 18) {
				var valFrequency = strPartNumber.substring(7, 15);
				if (valFrequency.indexOf('M') === 0) {
					valFrequency = '0' + valFrequency;
				}
				valFrequency = valFrequency.replace('M', '.');
				$('#dFrequency').val(parseFloat(valFrequency));
				if ($('#radio37_' + strPartNumber.substring(4, 5))) {
					$('#radio37_' + strPartNumber.substring(4, 5)).prop('checked', true);
				}
				if ($('#radio77_' + strPartNumber.substring(6, 7))) {
					$('#radio77_' + strPartNumber.substring(6, 7)).prop('checked', true);
				}
				if ($('#radio60_' + strPartNumber.substring(15, 16))) {
					$('#radio60_' + strPartNumber.substring(15, 16)).prop('checked', true);
				}
				if ($('#radio57_' + strPartNumber.substring(17, 18))) {
					if (strPartNumber.substring(17, 18) === "E") {
						$('#div_radio37_A').addClass('radio-disable');
						$('#div_radio37_B').addClass('radio-disable');
						$('#div_radio37_D').addClass('radio-disable');

						$('#radio37_A').prop('disabled', true);
						$('#radio37_B').prop('disabled', true);
						$('#radio37_D').prop('disabled', true);
					}
					$('#radio57_' + strPartNumber.substring(17, 18)).prop('checked', true);
				}
				if ($('#radio88_' + strPartNumber.substring(18))) {
					if (strPartNumber.substring(18) === "") {
						$('#btnRequest').prop('disabled', false);
					} else {
						$('#btnRequest').prop('disabled', true);
					}
					$('#radio88_' + strPartNumber.substring(18)).prop('checked', true);
				}
			}
		}
		function changeFrequencyStep() {
			var dFrequency = $('#dFrequency').val();
			var strFreq = dFrequency.toString();
			if (strFreq.indexOf('.') === -1) {
				$('#dFrequency').attr('step', '1');
			} else {
				var decimalLen = strFreq.length - strFreq.indexOf('.') - 1;
				if (decimalLen === 0) {
					$('#dFrequency').attr('step', '1');
				} else {
					var strStep = '0.';
					while (decimalLen > 1) {
						strStep = strStep + '0';
						decimalLen--;
					}
					strStep = strStep + '1';
					$('#dFrequency').attr('step', parseFloat(strStep));
				}
			}

			if (dFrequency < 1) {
				if (strFreq.length > 9) {
					strFreq = strFreq.substring(0, 9);
					$('#dFrequency').val(strFreq);
				}
			} else {
				if (strFreq.length > 8) {
					strFreq = strFreq.substring(0, 8);
					$('#dFrequency').val(strFreq);
				}
			}
		}

		$('.radio-group57').on('click', function () {
			if ($('#radio57_E').is(':checked')) {
				$('#radio37_A').prop('checked', false);
				$('#radio37_B').prop('checked', false);
				$('#radio37_D').prop('checked', false);

				$('#radio37_A').prop('disabled', true);
				$('#radio37_B').prop('disabled', true);
				$('#radio37_D').prop('disabled', true);

				$('#div_radio37_A').addClass('radio-disable');
				$('#div_radio37_B').addClass('radio-disable');
				$('#div_radio37_D').addClass('radio-disable');
			} else {
				$('#radio37_A').prop('disabled', false);
				$('#radio37_B').prop('disabled', false);
				$('#radio37_D').prop('disabled', false);

				$('#div_radio37_A').removeClass('radio-disable');
				$('#div_radio37_B').removeClass('radio-disable');
				$('#div_radio37_D').removeClass('radio-disable');
			}
		});
		$('.radio-as5003').on('click', function () {
			changePartNumber();
		});
		$('#dFrequency').on('click', function () {
			//changeFrequencyStep();
		});
		$('#dFrequency').keyup(function () {
			//changeFrequencyStep();
		});
		$('#dFrequency').keydown(function (event) {
			if (event.shiftKey == true) {
				event.preventDefault();
			}

			if ((event.keyCode >= 48 && event.keyCode <= 57) ||
				(event.keyCode >= 96 && event.keyCode <= 105) ||
				event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 37 ||
				event.keyCode == 39 || event.keyCode == 46 || event.keyCode == 190 || event.keyCode == 110) {

			} else {
				event.preventDefault();
			}

			if ($(this).val().indexOf('.') !== -1 && (event.keyCode == 190 || event.keyCode == 110))
				event.preventDefault();
			//if a decimal has been added, disable the "."-button
		});
		$('#dFrequency').on('change', function () {
			changePartNumber();
			//changeFrequencyStep();
		});
		$('.radio-group88').on('click', function () {
			if ($(this).val() === "") {
				$('#btnRequest').prop('disabled', false);
			} else {
				$('#btnRequest').prop('disabled', true);
			}
		});

		$('#selectOutput').on('change', function () {
			changePartNumber();
		});
		$('#chkEnableDecode').on('change', function () {
			if ($('#chkEnableDecode').is(':checked')) {
				$('#btnDecode').show();
				$('#partNumber').prop('readonly', false);
			} else {
				$('#btnDecode').hide();
				$('#partNumber').prop('readonly', true);
			}
		});
		$('#btnReset').on('click', function () {
			$('.radio-as5003').prop('checked', false);
			$('.radio-as5003').removeClass('radio-disable');
			$('.radio-as5003').prop('disabled',false)
			$('#dFrequency').val('');
			$('#partNumber').val('');
			$('#devI2cAddress').val('0x60');
			$('#btnRequest').prop('disabled', true)
		});
		$('#btnCopy1').on('click', function () {
			copyToClipboard($('#partNumber')[0]);
		});
		$('#btnDecode').on('click', function () {
			decodePartNumber();
		});
		$('#btnRequest').on('click', function (event) {
			if ($("#partNumber").val() == ''){
				event.preventDefault();
			}
			// var partNumber = $('#partNumber').val();
			// var devI2cAddress = $('#devI2cAddress').val();
			// var dFreq = $('#dFrequency').val();
			// if (partNumber && devI2cAddress) {
			// 	// var newUrl = gUrlWpCart + '?add-to-cart=' + gProductId + '&quantity=1&part_number=' + partNumber + '&i2c_addr=' + devI2cAddress + '&dfreq=' + dFreq;
			// 	// window.top.location.replace(newUrl);
			// } else {
			// 	event.preventDefault();
			// }
		});
		$('#btnRequestA').on('click', function () {
			if (gUrlRequestQuoteFrom) {
				window.top.location.replace(gUrlRequestQuoteFrom);
			}
		});
		$('#btnBuy').on('click', function () {
			var partNumber = $('#partNumber').val();
			if (partNumber && gUrlBuyOnDigiKey) {
				var newUrl = gUrlBuyOnDigiKey + '#' + partNumber;
				window.top.location.replace(newUrl);
			}
		});
		$.mask.definitions['~'] = "[+-]";
		$('#devI2cAddress').mask("0x7h");
		$('#devI2cAddress').on('focusout', function(){
			let value = parseInt(this.value) || 96
			if(value > 119)
				this.value = '0x77'
			else if(value < 8)
				this.value = '0x08' 
			else{
				this.value = "0x" + value.toString(16).padStart(2,'0')
			}
		})
	}
});