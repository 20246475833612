// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("trix")
require("@rails/actiontext")
//= require pagy

import $ from "jquery"
require("@popperjs/core")
require("bootstrap")

global.$ = global.jQuery = $;
window.$ = window.jQuery = $;

import Swiper, { Pagination, Navigation, Autoplay } from 'swiper'
Swiper.use([Pagination, Navigation, Autoplay ])

global.Swiper = Swiper;
window.Swiper = Swiper;

require('./chips/AS511.js')
require('./chips/AS512.js')
require('./chips/AS5001.js')
require('./chips/AS5002.js')
require('./chips/AS5003.js')
