"use strict";
$(document).on('turbolinks:load', function () {
	if ($('.as512-generator').is(':visible')) {
		// function resetAllRadio() {
		// 	$('.radio-as512').prop('checked', false);
		// 	$('.radio-as512').removeClass('radio-disable');
		// 	$('#dFrequency1').val('');
		// 	$('#dFrequency2').val('');
		// 	$('#dFrequency3').val('');
		// }
		function changePartNumber() {
			var frmDataRadio15 = $('#frmGroup15').serializeArray();
			var frmDataRadio18 = $('#frmGroup18').serializeArray();
			var frmDataRadio23 = $('#frmGroup23').serializeArray();
			var frmDataRadio26 = $('#frmGroup26').serializeArray();
			var frmDataRadio99 = $('#frmGroup99').serializeArray();

			$('#partNumber').val('');
			$('#btnRequest').prop('disabled', true)

			let arrFrequencies = [];
			for(let num of [1,2,3]){
				if(!$('#dFrequency' + num).prop('disabled')){
					var dFrequency = $('#dFrequency' + num).val();
					dFrequency = parseFloat(dFrequency);
	
					if (dFrequency && dFrequency !== 0) {
						if (dFrequency < 0.01) {
							dFrequency = 0.01;
						}
						if (dFrequency > 212.5) {
							dFrequency = 212.5;
						}
						$('#dFrequency' + num).val(dFrequency);
					}
					arrFrequencies.push(dFrequency)
				}
				
			}

			if (!arrFrequencies.includes(NaN) && frmDataRadio15.length > 0 && frmDataRadio18.length > 0 && frmDataRadio23.length > 0 && frmDataRadio26.length > 0 && frmDataRadio99.length > 0) {
				// var partNumber = '512' + frmDataOutput[0].value + 'A' + frmDataRadio26[0].value + strFrequency + frmDataRadio18[0].value + 'B' + frmDataRadio15[0].value + frmDataRadio23[0].value;
				var partNumber = JSON.stringify({
					chip: '512',
					totalStability: 'A',
					controlPin: 'A',
					disableFunction: frmDataRadio26[0].value,
					disableType: frmDataRadio99[0].value,
					frequencyLow: $('#dFrequency3').is(':disabled') ? '1' : $('#dFrequency3').val(),
					frequencyHigh: $('#dFrequency2').is(':disabled') ? '1' : $('#dFrequency2').val(),
					frequencyHiz: $('#dFrequency1').is(':disabled') ? '1' : $('#dFrequency1').val(),
					package: frmDataRadio18[0].value,
					deviceRevision: 'B',
					temperatureGrade: frmDataRadio15[0].value,
					packageQty: frmDataRadio23[0].value
				});
				$('#partNumber').val(partNumber);
				$('#btnRequest').prop('disabled', false)
			} else {
				$('#partNumber').val('');
				$('#btnRequest').prop('disabled', true)
			}
		}

		$('.radio-group15').on('click', function () {
			if ($('#radio15_E').is(':checked')) {
				$('#radio10_A').prop('checked', false);
				$('#radio10_B').prop('checked', false);
				$('#radio10_D').prop('checked', false);

				$('#radio10_A').prop('disabled', true);
				$('#radio10_B').prop('disabled', true);
				$('#radio10_D').prop('disabled', true);

				$('#div_radio10_A').addClass('radio-disable');
				$('#div_radio10_B').addClass('radio-disable');
				$('#div_radio10_D').addClass('radio-disable');

				$('#radio40_A').prop('checked', false);
				$('#radio40_B').prop('checked', false);
				$('#radio40_D').prop('checked', false);

				$('#radio40_A').prop('disabled', true);
				$('#radio40_B').prop('disabled', true);
				$('#radio40_D').prop('disabled', true);

				$('#div_radio40_A').addClass('radio-disable');
				$('#div_radio40_B').addClass('radio-disable');
				$('#div_radio40_D').addClass('radio-disable');

				$('#radio55_A').prop('checked', false);
				$('#radio55_B').prop('checked', false);
				$('#radio55_D').prop('checked', false);

				$('#radio55_A').prop('disabled', true);
				$('#radio55_B').prop('disabled', true);
				$('#radio55_D').prop('disabled', true);

				$('#div_radio55_A').addClass('radio-disable');
				$('#div_radio55_B').addClass('radio-disable');
				$('#div_radio55_D').addClass('radio-disable');
			} else {
				$('#radio10_A').prop('disabled', false);
				$('#radio10_B').prop('disabled', false);
				$('#radio10_D').prop('disabled', false);

				$('#div_radio10_A').removeClass('radio-disable');
				$('#div_radio10_B').removeClass('radio-disable');
				$('#div_radio10_D').removeClass('radio-disable');

				$('#radio40_A').prop('disabled', false);
				$('#radio40_B').prop('disabled', false);
				$('#radio40_D').prop('disabled', false);

				$('#div_radio40_A').removeClass('radio-disable');
				$('#div_radio40_B').removeClass('radio-disable');
				$('#div_radio40_D').removeClass('radio-disable');

				$('#radio55_A').prop('disabled', false);
				$('#radio55_B').prop('disabled', false);
				$('#radio55_D').prop('disabled', false);

				$('#div_radio55_A').removeClass('radio-disable');
				$('#div_radio55_B').removeClass('radio-disable');
				$('#div_radio55_D').removeClass('radio-disable');
			}
		});
		$('.radio-as512').on('click', function () {
			changePartNumber();
		});
		$('#dFrequency1').keyup(function () {
			//changeFrequencyStep(1);
		});
		$('#dFrequency2').keyup(function () {
			//changeFrequencyStep(2);
		});
		$('#dFrequency3').keyup(function () {
			//changeFrequencyStep(3);
		});
		$('#dFrequency1, #dFrequency2, #dFrequency3').keydown(function (event) {
			if (event.shiftKey == true) {
				event.preventDefault();
			}

			if ((event.keyCode >= 48 && event.keyCode <= 57) ||
				(event.keyCode >= 96 && event.keyCode <= 105) ||
				event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 37 ||
				event.keyCode == 39 || event.keyCode == 46 || event.keyCode == 190 || event.keyCode == 110) {

			} else {
				event.preventDefault();
			}

			if ($(this).val().indexOf('.') !== -1 && (event.keyCode == 190 || event.keyCode == 110))
				event.preventDefault();
			//if a decimal has been added, disable the "."-button
		});
		$('#dFrequency1').on('change', function () {
			changePartNumber();
			//changeFrequencyStep();
		});
		$('#dFrequency2').on('change', function () {
			changePartNumber();
			//changeFrequencyStep();
		});
		$('#dFrequency3').on('change', function () {
			changePartNumber();
			//changeFrequencyStep();
		});
		// $('.radio-group23').on('click', function() {
		// 	if($(this).val() === "") {
		// 		$('#btnRequest').prop('disabled', false);
		// 	} else {
		// 		$('#btnRequest').prop('disabled', true);
		// 	}
		// });

		$('#btnReset').on('click', function () {
			$('.radio-as512').prop('checked', false);
			$('.radio-as512').removeClass('radio-disable');
			$('.radio-as512').prop('disabled',false)

			$('#dFrequency1').prop('disabled',false).val('');
			$('#dFrequency2').prop('disabled',false).val('');
			$('#dFrequency3').prop('disabled',false).val('');
			$('#partNumber').val('');
			$('#btnRequest').prop('disabled', true)
		});
		$('#btnCopy1').on('click', function () {
			copyToClipboard($('#partNumber1')[0]);
		});
		$('#btnRequest').on('click', function (event) {
			if ($("#partNumber").val() == ''){
				event.preventDefault();
			}
		});
		$('#btnRequestA').on('click', function () {
			if (gUrlRequestQuoteFrom) {
				window.top.location.replace(gUrlRequestQuoteFrom);
			}
		});
		$('#btnBuy').on('click', function () {
			var partNumber = $('#partNumber1').val() + '|' + $('#partNumber2').val() + '|' + $('#partNumber3').val();
			if (partNumber && gUrlBuyOnDigiKey) {
				var newUrl = gUrlBuyOnDigiKey + '#' + partNumber;
				window.top.location.replace(newUrl);
			}
		});
		$('input[type=radio][name=radio26]').on('change', function() {
			var isCheckedA = ($('input[id="radio26_A"]').is(':checked'));
			var isCheckedB = ($('input[id="radio26_B"]').is(':checked'));
			var isCheckedC = ($('input[id="radio26_C"]').is(':checked'));
			var isCheckedD = ($('input[id="radio26_D"]').is(':checked'));

			if (isCheckedA) {
				$("input[id='dFrequency3']").attr("disabled", "disabled");
				$("input[id='dFrequency3']").val(null);
			}
			else {
				$("input[id='dFrequency3']").removeAttr("disabled");
			}

			if (isCheckedB) {
				$("input[id='dFrequency2']").attr("disabled", "disabled");
				$("input[id='dFrequency2']").val(null);
			}
			else {
				$("input[id='dFrequency2']").removeAttr("disabled");
			}

			if (isCheckedC) {
				$("input[id='dFrequency1']").attr("disabled", "disabled");
				$("input[id='dFrequency1']").val(null);
			}
			else {
				$("input[id='dFrequency1']").removeAttr("disabled");
			}

			if (isCheckedD) {
				$("input[id='dFrequency2']").removeAttr("disabled");
				$("input[id='dFrequency1']").removeAttr("disabled");
				$("input[id='dFrequency3']").removeAttr("disabled");
			}

			changePartNumber();

		});


	}
});