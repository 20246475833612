"use strict";
$(document).on('turbolinks:load', function () {
  if ($('.as511-generator').is(':visible')) {
    function copyToClipboard(elem) {
      // create hidden text element, if it doesn't already exist
      var targetId = "_hiddenCopyText_";
      var isInput = elem.tagName === "INPUT" || elem.tagName === "TEXTAREA";
      var origSelectionStart, origSelectionEnd;
      if (isInput) {
        // can just use the original source element for the selection and copy
        target = elem;
        origSelectionStart = elem.selectionStart;
        origSelectionEnd = elem.selectionEnd;
      } else {
        // must use a temporary form element for the selection and copy
        target = document.getElementById(targetId);
        if (!target) {
          var target = document.createElement("textarea");
          target.style.position = "absolute";
          target.style.left = "-9999px";
          target.style.top = "0";
          target.id = targetId;
          document.body.appendChild(target);
        }
        target.textContent = elem.textContent;
      }
      // select the content
      var currentFocus = document.activeElement;
      target.focus();
      target.setSelectionRange(0, target.value.length);

      // copy the selection
      var succeed;
      try {
        succeed = document.execCommand("copy");
      } catch (e) {
        succeed = false;
      }
      // restore original focus
      if (currentFocus && typeof currentFocus.focus === "function") {
        currentFocus.focus();
      }

      if (isInput) {
        // restore prior selection
        elem.setSelectionRange(origSelectionStart, origSelectionEnd);
      } else {
        // clear temporary content
        target.textContent = "";
      }
      return succeed;
    }

    // function resetAllRadio() {
    //   $('.radio-as511').prop('checked', false);
    //   $('.radio-as511').removeClass('radio-disable');
    //   $('#dFrequency').val('');
    // }

    function changePartNumber() {
      var frmDataRadio15 = $('#frmGroup15').serializeArray();
      var frmDataRadio18 = $('#frmGroup18').serializeArray();
      var frmDataRadio23 = $('#frmGroup23').serializeArray();
      var frmDataRadio26 = $('#frmGroup26').serializeArray();
      $('#partNumber').val('');
      $('#btnRequest').prop('disabled', true)

      var dFrequency;
      try {
        dFrequency = $('#dFrequency').val();
        if (!dFrequency) {
          return;
        }
        dFrequency = parseFloat(dFrequency);
        if (isNaN(dFrequency)) {
          alert('Please enter correct frequency');
        }
      } catch (e) {
        return;
      }
      if (dFrequency && dFrequency !== 0) {
        if (dFrequency < 0.01) {
          dFrequency = 0.01;
        }
        if (dFrequency > 212.5) {
          dFrequency = 212.5;
        }
        $('#dFrequency').val(dFrequency);
      } else {
        dFrequency = 0;
      }

      let strFrequency = '';

      if (dFrequency >= 100) {
        strFrequency = parseInt(dFrequency) + 'M' + Math.round((123 + 1 * dFrequency - parseInt(dFrequency)) * 10000);
      } else if (dFrequency >= 10) {
        strFrequency = parseInt(dFrequency) + 'M' + Math.round((123 + 1 * dFrequency - parseInt(dFrequency)) * 100000);
      } else if (dFrequency >= 1) {
        strFrequency = parseInt(dFrequency) + 'M' + Math.round((123 + 1 * dFrequency - parseInt(dFrequency)) * 1000000);
      } else if (dFrequency > 0) {
        strFrequency = 'M' + Math.round((123 + 1 * dFrequency - parseInt(dFrequency)) * 10000000);
      }
      if (strFrequency) {
        strFrequency = strFrequency.replace('M123', 'M');
      }

      if (strFrequency && frmDataRadio15.length > 0 && frmDataRadio18.length > 0 && frmDataRadio23.length > 0 && frmDataRadio26.length > 0) {

        let typeFrequency = '';
        if (dFrequency <= 50)
          typeFrequency = "A";
        else
          typeFrequency = "B";
        // var partNumber = '511' + typeFrequency + 'A' + frmDataRadio26[0].value + strFrequency + frmDataRadio18[0].value + 'B' + frmDataRadio15[0].value + frmDataRadio23[0].value;
        var partNumber = JSON.stringify({
          chip: '511',
          frequencyRange: typeFrequency,
          totalStability: 'A',
          controlPin: frmDataRadio26[0].value,
          frequency: $('#dFrequency').val(),
          package: frmDataRadio18[0].value,
          deviceRevision: 'B',
          temperatureGrade: frmDataRadio15[0].value,
          packageQty: frmDataRadio23[0].value
        });
        $('#partNumber').val(partNumber);
        $('#btnRequest').prop('disabled', false)

      } else {
        $('#partNumber').val('');
        $('#btnRequest').prop('disabled', true)
      }
    }

    function decodePartNumber() {
      // resetAllRadio();
      var strPartNumber = $('#partNumber').val();
      strPartNumber = $.trim(strPartNumber);
      if (strPartNumber.length >= 17) {
        var valFrequency = strPartNumber.substring(6, 15);
        if (valFrequency.indexOf('M') === 0) {
          valFrequency = '0' + valFrequency;
        }
        valFrequency = valFrequency.replace('M', '.');
        var dFrequency = parseFloat(valFrequency);
        $('#dFrequency').val(dFrequency);
        if ($('#radio26_' + strPartNumber.substring(5, 6))) {
          $('#radio26_' + strPartNumber.substring(5, 6)).prop('checked', true);
        }

        if ($('#radio23_' + strPartNumber.substring(17))) {
          if (strPartNumber.substring(18) === "") {
            $('#btnRequest').prop('disabled', false);
          } else {
            $('#btnRequest').prop('disabled', true);
          }
          $('#radio23_' + strPartNumber.substring(17)).prop('checked', true);
        }
      }
    }

    function changeFrequencyStep() {
      var dFrequency = $('#dFrequency').val();
      var strFreq = dFrequency.toString();
      if (strFreq.indexOf('.') === -1) {
        $('#dFrequency').attr('step', '1');
      } else {
        var decimalLen = strFreq.length - strFreq.indexOf('.') - 1;
        if (decimalLen === 0) {
          $('#dFrequency').attr('step', '1');
        } else {
          var strStep = '0.';
          while (decimalLen > 1) {
            strStep = strStep + '0';
            decimalLen--;
          }
          strStep = strStep + '1';
          $('#dFrequency').attr('step', parseFloat(strStep));
        }
      }

      if (dFrequency < 1) {
        if (strFreq.length > 9) {
          strFreq = strFreq.substring(0, 9);
          $('#dFrequency').val(strFreq);
        }
      } else {
        if (strFreq.length > 8) {
          strFreq = strFreq.substring(0, 8);
          $('#dFrequency').val(strFreq);
        }
      }
    }

    $('.radio-group15').on('click', function () {
      if ($('#radio15_E').is(':checked')) {
        $('#radio10_A').prop('checked', false);
        $('#radio10_B').prop('checked', false);
        $('#radio10_D').prop('checked', false);

        $('#radio10_A').prop('disabled', true);
        $('#radio10_B').prop('disabled', true);
        $('#radio10_D').prop('disabled', true);

        $('#div_radio10_A').addClass('radio-disable');
        $('#div_radio10_B').addClass('radio-disable');
        $('#div_radio10_D').addClass('radio-disable');
      } else {
        $('#radio10_A').prop('disabled', false);
        $('#radio10_B').prop('disabled', false);
        $('#radio10_D').prop('disabled', false);

        $('#div_radio10_A').removeClass('radio-disable');
        $('#div_radio10_B').removeClass('radio-disable');
        $('#div_radio10_D').removeClass('radio-disable');
      }
    });
    $('.radio-as511').on('click', function () {
      changePartNumber();
    });
    $('#dFrequency').on('click', function () {
      //changeFrequencyStep();
    });
    $('#dFrequency').keyup(function () {
      //changeFrequencyStep();
    });
    $('#dFrequency').keydown(function (event) {
      if (event.shiftKey == true) {
        event.preventDefault();
      }

      if ((event.keyCode >= 48 && event.keyCode <= 57) ||
        (event.keyCode >= 96 && event.keyCode <= 105) ||
        event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 37 ||
        event.keyCode == 39 || event.keyCode == 46 || event.keyCode == 190 || event.keyCode == 110) {

      } else {
        event.preventDefault();
      }

      if ($(this).val().indexOf('.') !== -1 && (event.keyCode == 190 || event.keyCode == 110))
        event.preventDefault();
      //if a decimal has been added, disable the "."-button
    });
    $('#dFrequency').on('change', function () {
      changePartNumber();
      //changeFrequencyStep();
    });
    $('.radio-group23').on('click', function () {

      if ($(this).val() === "") {
        $('#btnRequest').prop('disabled', false);
      } else {
        $('#btnRequest').prop('disabled', true);
      }
    });
    $('#chkEnableDecode').on('change', function () {
      if ($('#chkEnableDecode').is(':checked')) {
        $('#btnDecode').show();
        $('#partNumber').prop('readonly', false);
      } else {
        $('#btnDecode').hide();
        $('#partNumber').prop('readonly', true);
      }
    });
    $('#btnReset').on('click', function () {
      $('.radio-as511').prop('checked', false);
      $('.radio-as511').removeClass('radio-disable');
      $('.radio-as511').prop('disabled',false)
      $('#dFrequency').val('');
      $('#partNumber').val('');
      $('#btnRequest').prop('disabled', true)
    });
    $('#btnCopy1').on('click', function () {
      copyToClipboard($('#partNumber')[0]);
    });
    $('#btnDecode').on('click', function () {
      decodePartNumber();
    });
    $('#btnRequest').on('click', function (event) {
      if ($("#partNumber").val() == ''){
				event.preventDefault();
			}
      // var partNumber = $('#partNumber').val();
      // var dFreq = $('#dFrequency').val();
      // if (partNumber) {
      //   // var newUrl = gUrlWpCart + '?add-to-cart=' + gProductId + '&quantity=1&part_number=' + partNumber + '&dfreq=' + dFreq;
      //   // window.top.location.replace(newUrl);
      // } else {
      //   event.preventDefault();
      // }
    });
    $('#btnRequestA').on('click', function () {
      if (gUrlRequestQuoteFrom) {
        window.top.location.replace(gUrlRequestQuoteFrom);
      }
    });
    $('#btnBuy').on('click', function () {
      var partNumber = $('#partNumber').val();
      if (partNumber && gUrlBuyOnDigiKey) {
        var newUrl = gUrlBuyOnDigiKey + '#' + partNumber;
        window.top.location.replace(newUrl);
      }
    });
  }
});